const Backups = require("./models/Backups");
const Conversations = require("./models/Conversations");
const Members = require("./models/Members");
const Messages = require("./models/Messages");

module.exports = {
  Backups,
  Conversations,
  Members,
  Messages,
};
